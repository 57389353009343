<template>
  <BaseDraggableModal
      :name="modal_name"
      max-height="80vh"
      width="70vw"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :ref="modal_name">
    <v-card :loading="loading" :disabled="loading">
      <BaseModal>
        <v-toolbar class="draggable_selector" slot="header" dark color="primary" dense flat>
          <v-btn icon @click.stop="reset">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ page.title }}</v-toolbar-title>
          <v-spacer/>
          <v-btn :disabled="loading" text small @click.stop="save">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </v-toolbar>
        <div slot="content">
          <v-divider/>
          <v-card-text>
            <v-text-field
                :class="'ma-0 pt-'+$store.getters.lineheight"
                :label="$store.getters.translate('name')"
                v-model="record.name">
              <template #label>{{ $store.getters.translate('name') }}<span class="red--text"><strong>*</strong></span></template>
            </v-text-field>
            <v-select
                :class="'ma-0 pt-'+$store.getters.lineheight"
                :label="$store.getters.translate('model_type')"
                v-model="record.model_type"
                validate-on-blur
                :items="$lodash.sortBy(models, 'text')">
              <template #label>{{ $store.getters.translate('model_type') }}<span class="red--text"><strong>*</strong></span></template>
            </v-select>
            <v-select
                :class="'ma-0 pt-'+$store.getters.lineheight"
                :label="$store.getters.translate('linked_model')"
                v-model="record.linked_model"
                validate-on-blur
                :items="$lodash.sortBy(models, 'text')"
                clearable/>
            <v-select
                :class="'ma-0 pt-'+$store.getters.lineheight"
                :label="$store.getters.translate('event')"
                v-model="record.event"
                validate-on-blur
                :items="getEvents">
              <template #label>{{ $store.getters.translate('event') }}<span class="red--text"><strong>*</strong></span></template>
            </v-select>
          </v-card-text>
        </div>
      </BaseModal>
    </v-card>
  </BaseDraggableModal>
</template>

<script>
import models from "./models.js";
import BaseModal from "../../components/commonComponents/BaseModal";
import BaseDraggableModal from "../../components/commonComponents/BaseDraggableModal";
import helpFunctions from "../../plugins/helpFunctions";

export default {
  components: {
    BaseModal,
    BaseDraggableModal,
  },
  props: ["modal_name"],
  data() {
    return {
      page: {
        title: this.$store.getters.translate("workflows"),
        name: "workflows",
        model: "workflow",
      },
      record: {
        name: null,
      },
      loading: false,
    };
  },
  methods: {
    ...helpFunctions.modal_functions,
    save() {
      if(!this.loading) {
        let usual_fields_required = [];
        usual_fields_required['name'] = true;
        usual_fields_required['model_type'] = true;
        usual_fields_required['event'] = true;
        let error = false;
        for (let key in usual_fields_required) {
          if (usual_fields_required[key] && (!this.record[key] || this.record[key] === '')) {
            this.$toasted.error(this.$store.getters.translate("required_field") + ": " + this.$store.getters.translate(key));
            error = true;
          }
        }
        if (!error) {
          this.loading = true;
          this.$http
              .post(this.$store.getters.appUrl + "v2/" + this.page.name, this.record)
              .then((response) => {
                this.loading = false;
                if (response.status == 200) {
                  this.$toasted.success(this.$store.getters.translate("successfully_saved"));
                  this.$router.push("/workflows/" + response.data.id);
                  this.closeModal(this.modal_name);
                } else {
                  var errorMessage = response.data.message;
                  var errors = Object.keys(response.data.message);
                  this.$toasted.error([errorMessage[errors[0]]]);
                }
              })
              .catch((error) => {
                if (this.$store.getters.isLoggedIn) {
                  this.$toasted.error(error);
                }
                this.loading = false;
              });
        }
      }
    },
    reset() {
      this.record = {};
      this.closeModal(this.modal_name);
    },
  },
  computed: {
    models() {
      return models;
    },
    getEvents() {
      return this.record.model_type ? this.$lodash.sortBy(this.models.find((model) => model.value === this.record.model_type).children, "text") : [];
    },
  },
};
</script>
